<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="per_page"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="per_pageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
            <b-button
              class="ml-1"
              variant="primary"
              @click="addArticleModal = true"
            >
              Add Article
            </b-button>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- Add Article Modal -->
      <b-modal
        v-model="addArticleModal"
        hide-footer
        centered
        size="lg"
        title="Add Article"
      >
        <b-form-group label="Enter the Article's Title" label-for="enterTitle">
          <b-form-input
            id="enterTitle"
            placeholder="Enter the Article's Title"
            v-model="addArticleTitle"
          />
        </b-form-group>
        <b-form-group
          label="Select Article's Categories"
          label-for="selectCategories"
        >
          <div class="demo-inline-spacing">
            <b-form-checkbox v-for="category in categories" :key="category.value" v-model="addArticleCategories" :value="category.value">
              {{ category.text }}
            </b-form-checkbox>
          </div>
        </b-form-group>

        <b-form-group label="Article Content" label-for="articleContent">
          <b-form-textarea
            v-model="addArticleContent"
            placeholder="Enter the content of the article"
            rows="10"
            :state="addArticleContent.length <= maxChar"
            class="char-textarea"
            :class="addArticleContent.length >= maxChar ? 'text-danger' : ''"
          />
          <small
            class="textarea-counter-value float-right"
            :class="addArticleContent.length >= maxChar ? 'bg-danger' : ''"
          >
            <span class="char-count">{{ addArticleContent.length }}</span> /
            {{ maxChar }}
          </small>
        </b-form-group>

        <b-form-group label="Select the Article's Cover Image" label-for="selectFile">
          <b-form-file
            v-model="addArticleImage"
            placeholder="Choose a file or drop it here..."
            drop-placeholder="Drop file here..."
            accept=".jpg,.jpeg,.png"
          />

          <b-card-text class="my-1">
            Selected file: <strong>{{ addArticleImage ? addArticleImage.name : "" }}</strong>
          </b-card-text>
        </b-form-group>

        <b-button variant="outline-primary" @click="storeArticles()">
          Submit
        </b-button>
      </b-modal>

      <b-table
        ref="refArticlesListTable"
        class="position-relative"
        :items="fetchArticles"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <!-- Column: Author -->
        <template #cell(author)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              data.item.user
                ? data.item.user.first_name+' '+data.item.user.last_name
                : data.item.admin.first_name+' '+data.item.admin.last_name
            }}</span>
          </div>
        </template>

        <!-- Column: Uploaded on -->
        <template #cell(uploaded-on)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{
              moment(data.item.created_at).format("dddd, MMMM Do YYYY")
            }}</span>
          </div>
        </template>

        <!-- Column: Status -->
        <template #cell(status)="data">
          <b-badge
            pill
            :variant="`light-${resolveArticleStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ data.item.status }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data"
          ><b-button
            variant="outline-info"
            :to="{ name: 'apps-article-view', params: { id: data.item.id } }"
          >
            Details
          </b-button>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="current_page"
              :total-rows="totalArticles"
              :per-page="per_page"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BFormGroup,
  BFormSelect,
  BModal,
  BFormCheckbox,
  BFormTextarea,
  BFormFile,
  BCardText,
} from "bootstrap-vue";
import vSelect from "vue-select";
import store from "@/store";
import { ref, onUnmounted } from "@vue/composition-api";
import useArticlesList from "./useArticlesList";
import articleStore from "./articleStore";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BFormGroup,
    BFormSelect,
    BModal,
    BFormCheckbox,
    BFormTextarea,
    BFormFile,
    BCardText,

    vSelect,
  },
  setup() {
    const ARTICLE_APP_STORE_MODULE_NAME = "app-articles";

    const maxChar = ref(1000);

    const categories = [];

    const fetchCategories = () => {
      store
        .dispatch("app-articles/fetchAllCategories")
        .then((response) => {
          response.data.forEach((category) => {
            categories.push({ value: category.id, text: category.name });
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    // Register module
    if (!store.hasModule(ARTICLE_APP_STORE_MODULE_NAME))
      store.registerModule(ARTICLE_APP_STORE_MODULE_NAME, articleStore);

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(ARTICLE_APP_STORE_MODULE_NAME))
        store.unregisterModule(ARTICLE_APP_STORE_MODULE_NAME);
    });

    fetchCategories();

    const isAddNewArticleSidebarActive = ref(false);

    const statusOptions = [
      { label: "Approved", value: "approved" },
      { label: "Awaiting Review", value: "awaiting review" },
      { label: "Requires Action", value: "requires action" },
    ];

    const {
      fetchArticles,
      storeArticles,
      tableColumns,
      per_page,
      current_page,
      totalArticles,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refArticlesListTable,
      refetchData,

      // UI
      resolveArticleStatusVariant,

      // Extra Filters
      statusFilter,

      addArticleModal,
      addArticleTitle,
      addArticleCategories,
      addArticleContent,
      addArticleImage,
    } = useArticlesList();

    return {
      // Sidebar
      isAddNewArticleSidebarActive,

      fetchArticles,
      storeArticles,
      tableColumns,
      per_page,
      current_page,
      totalArticles,
      dataMeta,
      per_pageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refArticlesListTable,
      refetchData,

      // UI
      resolveArticleStatusVariant,
      statusOptions,

      // Extra Filters
      statusFilter,

      categories,
      maxChar,

      // Add Article
      addArticleModal,
      addArticleTitle,
      addArticleCategories,
      addArticleContent,
      addArticleImage,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
